<template>
  <div id="app" style="padding:15px; overflow:auto; width:calc(100% - 30px)">
    <loading :loading="loading" text=""></loading>
    <el-form ref="form" :model="paper" :rules="rules"  label-width="100px">
      <el-form-item label="试卷名称" prop="name">
        <el-input type="text"  v-model="paper.name" placeholder="请输入" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="模考类型">
        <el-radio v-model="paper.isMatch" :label="false">普通模考</el-radio>
        <el-radio v-model="paper.isMatch" :label="true" v-show="paper.projectId == projectEnum.FA_KAO">模考大赛</el-radio>
      </el-form-item>
      <el-form-item label="模考时间" :required="true" v-show="paper.isMatch == true">
        <el-date-picker
            v-model="dateTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="试题来源" prop="sourceId">
        <el-radio v-model="paper.sourceId" :label="1">真题</el-radio>
        <el-radio v-model="paper.sourceId" :label="25">模拟题</el-radio>
      </el-form-item>
      <el-form-item label="考试轮次" v-show="paper.projectId == projectEnum.FA_KAO">
        <el-radio v-for="round in rounds" v-model="paper.roundId" :label="round.id">
          {{round.name}}
        </el-radio>
      </el-form-item>
      <el-form-item label="模考范围">
        <el-radio v-model="paper.moduleId" :label="moduleEnum.MOCK">公开模考</el-radio>
        <el-radio v-model="paper.moduleId" :label="moduleEnum.ZSMK">专属模考</el-radio>
      </el-form-item>
      <course-form-item-multiple ref="courseFormItem" v-show="paper.moduleId == moduleEnum.ZSMK"></course-form-item-multiple>
      <el-form-item label="出题方式">
        <el-radio v-model="paper.makeWay" :label="makeWayEnum.MANUAL">人工选题</el-radio>
        <el-radio v-model="paper.makeWay" :label="makeWayEnum.AUTO">智能出题</el-radio>
        <el-button type="primary" v-show="paper.makeWay == makeWayEnum.AUTO" @click="autoQuestions()">开始出题</el-button>
      </el-form-item>

      <div id="divKG" v-show="paper.roundId == 1 || paper.roundId == 2">
        <el-form-item label="单选题">
          <div class="layui-input-block" style="height: 26px; min-height: 26px; color: #40AFFE; cursor: pointer" @click="handleClick('danXuan')">选择题目 >></div>
          <div id="divDanXuanQuestions" class="layui-input-block question-list">
            <p v-for="(question,index) in this.danXuanQuestions">
              <span style='color: #333'>{{(index+1) + ". ["+question.id+"]"}} </span>
              <span style='color: #666'> {{question.stem}}</span>
              <span style='margin-left: 10px; color:#FF5722;cursor: pointer;' @click="removeDanXuanQuestion(question.id);">[删除]</span>
            </p>
          </div>
        </el-form-item>
        <el-form-item label="多选题">
          <div class="layui-input-block" style="height: 26px; min-height: 26px; color: #40AFFE; cursor: pointer" @click="handleClick('duoXuan')">选择题目 >></div>
          <div id="divDuoXuanQuestions" class="layui-input-block question-list">
            <p v-for="(question,index) in this.duoXuanQuestions">
              <span style='color: #333'>{{(index+1) + ". ["+question.id+"]"}} </span>
              <span style='color: #666'> {{question.stem}}</span>
              <span style='margin-left: 10px; color:#FF5722;cursor: pointer;' @click="removeDuoXuanQuestion(question.id);">[删除]</span>
            </p>
          </div>
        </el-form-item>
        <el-form-item label="不定项">
          <div class="layui-input-block" style="height: 26px; min-height: 26px; color: #40AFFE; cursor: pointer" @click="handleClick('bdx')">选择题目 >></div>
          <div  id="divBdxQuestions" class="layui-input-block question-list" style="display: block">
            <p v-for="(question,index) in this.bdxQuestions">
              <span style='color: #333'>{{(index+1) + ". ["+question.id+"]"}} </span>
              <span style='color: #666'> {{question.stem}}</span>
              <span style='margin-left: 10px; color:#FF5722;cursor: pointer;' @click="removeBdxQuestion(question.id);">[删除]</span>
            </p>
          </div>
        </el-form-item>
      </div>
      <div id="divZG" v-show="paper.roundId == 3">
        <el-form-item label="主观题">
          <div class="layui-input-block" style="height: 26px; min-height: 26px; color: #40AFFE; cursor: pointer" @click="handleClick('zg')">选择题目 >></div>
          <div  id="divClQuestions" class="layui-input-block question-list">
            <p v-for="(question,index) in this.clQuestions">
              <span style='color: #333'>{{(index+1) + ". ["+question.id+"]"}} </span>
              <span style='color: #666'> {{question.stem}}</span>
              <span style='margin-left: 10px; color:#FF5722;cursor: pointer;' @click="removeClQuestion(question.id);">[删除]</span>
            </p>
          </div>
        </el-form-item>
      </div>
      <el-form-item style="display: none">
        <el-button @click="submit()" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  TipEnum,
  UrlEnum,
  getLocalProjectId,
  ModuleEnum,
  QuestionTypeEnum,
    RoundEnum,
    PaperMakeWayEnum,
    ProjectEnum,
  request,
} from "../../../../public/js/common-vue";
import CourseFormItemMultiple from "@/components/CourseFormItemMultiple";
import Loading from "@/components/Loading";
export default {
  name: "MockPaperSave",
  data() {
    return {
      loading: false,
      rounds: [],
      danXuanQuestions:[],
      duoXuanQuestions:[],
      bdxQuestions:[],
      clQuestions:[],
      currentDiv:'',
      id:0,
      dateTime: [new Date(), new Date()],
      moduleEnum:ModuleEnum,
      makeWayEnum:PaperMakeWayEnum,
      projectEnum:ProjectEnum,
      paper: {
        name: '',
        sourceId: 1,
        roundId: 0,
        projectId: getLocalProjectId(),
        moduleId: ModuleEnum.MOCK,
        makeWay:PaperMakeWayEnum.MANUAL,
        isMatch:false
      },
      rules: {
        name: [{required: true, message: '请输入', trigger: 'blur'}],
      },
    }
  },
  mounted() {
    this.getRounds();
    if(request('id') != null && request('id') != ''){
      this.id=request('id');
      this.initData();
    }
    else{
      this.$refs.courseFormItem.initData(null,null);
    }
  },
  methods: {
    initData(){
      this.$http({
        method: "get",
        url: UrlEnum.PAPER + "/" + request('id'),
      }).then((res) => {
        this.paper = res.data;
        console.log(this.paper);
        this.dateTime=[this.paper.startTime,this.paper.endTime];
        this.getQuestions(this.paper.danxuanIds,QuestionTypeEnum.DAN_XUAN);
        this.getQuestions(this.paper.duoxuanIds,QuestionTypeEnum.DUO_XUAN);
        this.getQuestions(this.paper.bdxIds,QuestionTypeEnum.BU_DING_XIANG);
        this.getQuestions(this.paper.clIds,QuestionTypeEnum.CAILIAO);
        this.$refs.courseFormItem.initData(this.paper.courses,null);
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    autoQuestions() {
      this.loading = true;
      this.danXuanQuestions=[];
      this.duoXuanQuestions=[];
      this.bdxQuestions=[];
      this.clQuestions=[];
      this.$http({
        method: "get",
        url: UrlEnum.PAPER_AUTO_QUESTIONS+"?sourceId="+this.paper.sourceId+"&roundId="+this.paper.roundId
      }).then((res) => {
        this.loading = false;
        let questions = res.data.list;
        for(var i=0;i<questions.length;i++){
          const question = questions[i];
          question.stem = question.stem.substring(0,150);
          if(question.typeId == QuestionTypeEnum.DAN_XUAN){
            this.danXuanQuestions.push(question);
          }
          else if(question.typeId == QuestionTypeEnum.DUO_XUAN){
            this.duoXuanQuestions.push(question);
          }
          else if(question.typeId == QuestionTypeEnum.BU_DING_XIANG){
            this.bdxQuestions.push(question);
          }
          else if(questionType == QuestionTypeEnum.CAILIAO){
            this.clQuestions.push(question);
          }
        }
      }).catch((res) => {
        this.loading = false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getQuestions(ids,questionType) {
        if(ids == ""){
          return;
        }
        this.$http({
          method: "get",
          url: UrlEnum.QUESTION+"?page=1&size=100&ids="+ids,
        }).then((res) => {
          let questions = res.data.list;
          for(var i=0;i<questions.length;i++){
            questions[i].stem = questions[i].stem.substring(0,150);
          }

          if(questionType == QuestionTypeEnum.DAN_XUAN){
            this.danXuanQuestions = questions;
          }
          else if(questionType == QuestionTypeEnum.DUO_XUAN){
            this.duoXuanQuestions = questions;
          }
          else if(questionType == QuestionTypeEnum.BU_DING_XIANG){
            this.bdxQuestions = questions;
          }
          else if(questionType == QuestionTypeEnum.CAILIAO){
            this.clQuestions = questions;
          }
        }).catch((res) => {
          if(res.response != null){
            const data = res.response.data;
            this.showMsgError(data.message);
          }
        });
    },
    handleClick(typeIds){
      let selectedQuestions = [];
      if(typeIds == 'danXuan'){
        typeIds = QuestionTypeEnum.DAN_XUAN;
        selectedQuestions = this.danXuanQuestions;
      }
      else if(typeIds == 'duoXuan'){
        typeIds = QuestionTypeEnum.DUO_XUAN;
        selectedQuestions = this.duoXuanQuestions;
      }
      else if(typeIds == 'bdx'){
        typeIds = QuestionTypeEnum.BU_DING_XIANG;
        selectedQuestions = this.bdxQuestions;
      }
      else if(typeIds == 'zg'){
        typeIds = QuestionTypeEnum.CAILIAO + "," + QuestionTypeEnum.JIAN_DA + "," + QuestionTypeEnum.LUN_SHU + "," + QuestionTypeEnum.FA_TIAO + "," + QuestionTypeEnum.AN_LI + "," + QuestionTypeEnum.FEN_XI + "," + QuestionTypeEnum.FENXI_LUNSHU;
        selectedQuestions = this.clQuestions;
      }
      this.$router.push({
        name:'SelectQuestion',
        params:{
          typeIds:typeIds,
          selectedQuestions:selectedQuestions
        }
      })
    },
    removeDanXuanQuestion(id){
      for(let i = this.danXuanQuestions.length-1; i>=0; i--){
        if(this.danXuanQuestions[i].id == id){
          this.danXuanQuestions.splice(i,1);
        }
      }
    },
    removeDuoXuanQuestion(id){
      for(let i = this.duoXuanQuestions.length-1; i>=0; i--){
        if(this.duoXuanQuestions[i].id == id){
          this.duoXuanQuestions.splice(i,1);
        }
      }
    },
    removeBdxQuestion(id){
      for(let i = this.bdxQuestions.length-1; i>=0; i--){
        if(this.bdxQuestions[i].id == id){
          this.bdxQuestions.splice(i,1);
        }
      }
    },
    removeClQuestion(id){
      for(let i = this.clQuestions.length-1; i>=0; i--){
        if(this.clQuestions[i].id == id){
          this.clQuestions.splice(i,1);
        }
      }
    },
    getRounds: function () {
      this.$http({
        method: "get",
        url: UrlEnum.EXAM_ROUND,
      }).then((res) => {
        this.rounds = res.data.list;
        this.paper.roundId = this.rounds[0].id;
      }).catch((res) => {
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getDanXuanIds() {
      var ids = [];
      for(let i = 0; i < this.danXuanQuestions.length; i++){
        const question = this.danXuanQuestions[i];
        ids.push(question.id);
      }
      return ids;
    },
    getDuoXuanIds() {
      var ids = [];
      for(var i = 0;i < this.duoXuanQuestions.length; i++){
        const question = this.duoXuanQuestions[i];
        ids.push(question.id);
      }
      return ids;
    },
    getBdxIds() {
      var ids = [];
      for(let i = 0; i < this.bdxQuestions.length; i++){
        const question = this.bdxQuestions[i];
        ids.push(question.id);
      }
      return ids;
    },
    getClIds() {
      const ids = [];
      for(var i = 0;i < this.clQuestions.length; i++){
        const question = this.clQuestions[i];
        ids.push(question.id);
      }
      return ids;
    },
    submit:function (){
      if(this.paper.name == ""){
        this.showMsgError("请输入试卷名称");
        return;
      }
      if(this.dateTime == null){
        this.showMsgError("请选择 开始/结束时间");
        return;
      }
      if(this.paper.moduleId == ModuleEnum.ZSMK  && this.$refs.courseFormItem.getCheckedIds().length == 0){
        this.showMsgError("请选择课程");
        return;
      }

      this.paper.startTime = this.moment(this.dateTime[0]).format("YYYY-MM-DD HH:mm:ss");
      this.paper.endTime = this.moment(this.dateTime[1]).format("YYYY-MM-DD HH:mm:ss");
      if(this.paper.roundId == RoundEnum.KG1 || this.paper.roundId == RoundEnum.KG2){
        this.paper.danxuanIds = this.getDanXuanIds();
        this.paper.duoxuanIds = this.getDuoXuanIds();
        this.paper.bdxIds = this.getBdxIds();
        //防止试卷类型与题目不匹配
        this.paper.clIds = [];
      }
      else{
        this.paper.clIds = this.getClIds();
        //防止试卷类型与题目不匹配
        this.paper.danxuanIds = [];
        this.paper.duoxuanIds = [];
        this.paper.bdxIds = [];
      }
      this.paper.des = '';
      if(this.paper.moduleId == ModuleEnum.ZSMK){
        this.paper.courseIds = this.$refs.courseFormItem.getCourseIds();
      }else{
        this.paper.courseIds = [];
      }
      console.log(JSON.stringify(this.paper));
      //保存课程会话
      this.$refs.courseFormItem.setSessionCourse(this.$refs.courseFormItem.getCheckedCourses());
      this.loading = true;

      var url = UrlEnum.PAPER;
      var method = "post";

      if(this.id != 0){
        url += "/"+this.id;
        method = "put";
      }

      this.$http({
        method: method,
        url: url,
        data: this.paper,
      })
          .then((res) => {
            this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
            const index = parent.layer.getFrameIndex(window.name); //先得到当前iframe层的索引
            setTimeout(function () {
              parent.layui.table.reload('tableData'); //重载表格
              parent.layer.close(index); //再执行关闭
            }, 800);
          })
          .catch((res) => {
            this.loading=false;
            if(res.response != null){
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          });
    },
  },
  activated() {
    let questions = this.$route.params.selectedQuestions;
    if(questions != null && questions.length > 0){
      if(questions[0].id != null){
         if(questions[0].typeId == QuestionTypeEnum.DAN_XUAN){
          this.danXuanQuestions = questions;
          console.log(this.danXuanQuestions);
        }
        else if(questions[0].typeId == QuestionTypeEnum.DUO_XUAN){
          this.duoXuanQuestions = questions;
        }
        else if(questions[0].typeId == QuestionTypeEnum.BU_DING_XIANG){
          this.bdxQuestions = questions;
        }
        else if(questions[0].typeId == QuestionTypeEnum.CAILIAO){
          this.clQuestions = questions;
        }
      }
    }
  },
  components: {Loading, CourseFormItemMultiple}
}
</script>

<style scoped>
@import "../../../../static/css/add.css";
.question-list{
  border: 1px solid #eee;
  padding: 10px;
  margin-top: 10px;
  min-height: 36px;
}
.question-list p{
  padding:0px;
  line-height: 20px;
}
</style>
